<template>
    <!-- Page: pages/org-chart -->
    <div class="page-container org-chart-page">

        <div class="container" v-if="showSearch">
            <div class="filter-wrapper">
                <search-block
                    :title="'What can we help you find?'"
                    :placeholder="'Search agencies, job titles, people and more'"
                    :type="'autocomplete'"
                />
            </div>
        </div>

        <br/>

        <breadcrumbs :items="path"/>

        <div class="container">
            <alert/>
        </div>

        <hr/>

        <div class="container" style="display: flow-root;" v-if="user">
            <userTools/>
        </div>

        <br/>

        <div class="container">
            <general-container title="State of Florida Agency Organizational Chart" allow-fullscreen="1" custom-class="org-chart-container">
                <org-tree-chart/>
            </general-container>
        </div>

    </div>
    <!-- End Page: pages/org-chart -->
</template>

<script>
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "./breadcrumbs";
    import UserTools from "@/alivue/components/user-tools";
    import useUser from "@/helpers/useUser";
    import ButtonLink from "./button-link";
    import GeneralContainer from "./general-container";
    import SearchBlock from "./search-block";
    import { computed } from "vue";
    import { useStore } from "vuex";
    import OrgTreeChart from "./org-tree-chart";

    export default {
        components: {
            OrgTreeChart,
            Alert,
            UserTools,
            Breadcrumbs,
            ButtonLink,
            GeneralContainer,
            SearchBlock,
        },
        props: {
        },
        methods: {
        },
        setup(props) {
            const {user} = useUser();

            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            return {
                user,
                path: [{title: 'Organizational Chart'}],
                showSearch,
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
